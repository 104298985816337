<template>
    <div class="reconciliation-summary">
        <template v-if="isLoading">
            <ReconciliationSummaryItem
                v-for="item in 5"
                :key="item"
                :isLoading="isLoading"
                :summaryItemTitle="'The total price here yoo'"
                :summaryItemTotal="4000"
            />
        </template>
        <template v-else>
            <ReconciliationSummaryItem
                v-for="summaryItem in summaryItems"
                :key="getSummaryItemId(summaryItem)"
                :summaryItemId="getSummaryItemId(summaryItem)"
                :summaryItemTitle="getSummaryItemTitle(summaryItem)"
                :summaryItemEmit="getSummaryItemEmit(summaryItem)"
                :summaryItemAction="getSummaryItemAction(summaryItem)"
                :summaryItemTotal="getSummaryItemTotal(summaryItem)"
                :summaryItemTotalType="getSummaryItemTotalType(summaryItem)"
                :summaryItemAlarm="getSummaryItemAlarm(summaryItem)"
                @onSummaryItemClick="onSummaryItemClick"
            />
        </template>
    </div>
</template>

<script>
import ReconciliationSummaryItem from './ReconciliationSummaryItem.vue';

const SUMMARY_COUNTABLE_ITEMS_IDS = {
    COUNT_MAP_REVISED: "countMapRevised",
    COUNT_SUPPLIER_REVISED: "countSupplierRevised",
    VALID_COUNT: "validCount",
}

export default {
    name: 'ReconciliationSummary',
    components: {ReconciliationSummaryItem},
    props: {
        summaryData: {
            type: Object,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        summaryItems() {
            return this.summaryData?.items || []
        },
        summaryItemCount() {
            return this.summaryData?.itemCount || 0
        }
    },
    methods: {
        onSummaryItemClick(emit = '') {
            this.$emit('onSummaryItemClick', emit)
        },
        getSummaryItemProp(summaryItem = {}, propKey = '') {
            return summaryItem[propKey] ?? null;
        },
        getSummaryItemId(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'id') ?? '');
        },
        getSummaryItemTitle(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'title') ?? '');
        },
        getSummaryItemTotal(summaryItem = {}) {
            if (Object.values(SUMMARY_COUNTABLE_ITEMS_IDS).includes(summaryItem?.id)) {
                return this.summaryItemCount - parseFloat(this.getSummaryItemProp(summaryItem, 'total') ?? 0)
            }
            return parseFloat(this.getSummaryItemProp(summaryItem, 'total') ?? 0);
        },
        getSummaryItemEmit(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'emit') ?? '');
        },
        getSummaryItemAction(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'action') ?? '');
        },
        getSummaryItemTotalType(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'totalType') ?? 'price');
        },
        getSummaryItemAlarm(summaryItem = {}) {
            return String(this.getSummaryItemProp(summaryItem, 'alarm') ?? '');
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-summary {
        display: flex;
    }
</style>
