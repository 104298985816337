<template>
    <div class="reconciliation-summary-item">
        <div class="reconciliation-summary-item__title">
            <BasePreloaderSkeleton v-if="isLoading" />
            {{summaryItemTitle}}
        </div>
        <div
            :class="['reconciliation-summary-item__total', getSummaryItemCountState]"
        >
            <BasePreloaderSkeleton v-if="isLoading" />
            
            {{summaryItemTotalAdapted}}
            
            <IconAlarm 
                v-if="summaryItemAlarm"
                v-tooltip="summaryItemAlarm"
                class="reconciliation-summary-item__alarm"
            />
        </div>
        <div
            v-if="isSummaryActionVisible"
            class="reconciliation-summary-item__action"
            @click="onSummaryItemClick"
        >
            <BasePreloaderSkeleton v-if="isLoading" />
            {{summaryItemAction}}
        </div>
    </div>
</template>

<script>
import BasePreloaderSkeleton from '../../Base/BasePreloaderSkeleton.vue';
import UtilNumbers from '../../../utils/UtilNumber';
import IconAlarm from '../../../components/Icons/IconAlarm.vue';
export default {
    name: 'ReconciliationSummaryItem',
    components: {
        BasePreloaderSkeleton,
        IconAlarm
    },
    props: {
        summaryItemId: {
            type: String,
            default: ''
        },
        summaryItemTitle: {
            type: String,
            default: ''
        },
        summaryItemEmit: {
            type: String,
            default: ''
        },
        summaryItemAction: {
            type: String,
            default: ''
        },
        summaryItemTotal: {
            type: Number,
            default: 0
        },
        summaryItemTotalType: {
            type: String,
            default: 'price',
            validator: (value) => ['price', 'count'].includes(value)
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        summaryItemAlarm: {
            type: String,
            default: ''
        }
    },
    computed: {
        summaryItemTotalAdapted() {
            const summaryItemTotalAdapted = this.summaryItemTotalType === 'price' ? UtilNumbers.toPrice(this.summaryItemTotal) : UtilNumbers.toLocaleNum(this.summaryItemTotal);
            return summaryItemTotalAdapted;
        },
        isSummaryActionVisible() {
            return !!this.summaryItemEmit && !!this.summaryItemAction && !!this.summaryItemTotal
        },
        getSummaryItemCountState() {
            if (this.summaryItemTotalType !== 'count') return ''

            if (this.summaryItemTotal === 0) return 'isSuccess'
            return 'isDanger'
        }
    },
    methods: {
        onSummaryItemClick() {
            this.$emit('onSummaryItemClick', this.summaryItemEmit)
        },
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-summary-item {
        display: flex;
        flex-direction: column;
        width: 150px;
        padding: 10px;
        margin-right: 10px;
        border-radius: 10px;
        background: #FFFFFF;

        &__title {
            position: relative;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: #AAB0B8;
        }

        &__total {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin-top: 5px;
            margin-bottom: 11px;
            display: flex;
            align-items: center;

            &.isSuccess {
                color: #71C376;
            }
            &.isDanger {
                color: #FF6666;
            }
        }

        &__action {
            position: relative;
            font-size: 10px;
            font-weight: 400;
            line-height: 12px;
            color: #4A91F1;
            cursor: pointer;
            margin-top: auto;
        }

        &__alarm {
            margin-left: 10px;
            cursor: pointer;
        }
    }
</style>
