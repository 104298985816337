<template>
    <div
        :class="{'control-panel-block_disabled': blockIsDisabled}"
        class="control-panel-block"
    >
        <div class="control-panel-block__info control-panel-block__section">
            <div class="control-panel-block__info-title">
                {{ blockInfoTitle }}
            </div>
            <div class="control-panel-block__info-count">
                {{ blockInfoCount }}
            </div>
        </div>
        <div class="control-panel-block__actions">
            <ControlPanelBlockAction
                v-for="blockAction in blockActions"
                :key="getBlockActionId(blockAction)"
                :action="blockAction"
                :isDisabled="blockIsDisabled"
                class="control-panel-block__section"
                @onClickAction="onClickAction($event, getBlockActionId(blockAction))"
            >
                <template #iconAction="{iconComponentName = ''}">
                    <slot
                        name="iconAction"
                        :iconComponentName="iconComponentName"
                    />
                </template>
            </ControlPanelBlockAction>
        </div>
    </div>
</template>

<script>
import ControlPanelBlockAction from './ControlPanelBlockAction.vue';
import UtilNumber from '../../utils/UtilNumber';
export default {
    name: 'ControlPanelBlock',
    components: {ControlPanelBlockAction},
    props: {
        block: {
            type: Object,
            default: () => ({})
        },
    },
    computed: {
        blockInfoTitle() {
            return String(this.block?.infoTitle ?? '');
        },
        blockInfoCountNum() {
            return parseFloat(this.block?.infoCount ?? 0);
        },
        blockInfoCount() {
            return UtilNumber.toLocaleNum(this.blockInfoCountNum);
        },
        blockActions() {
            return this.block?.actions ?? [];
        },
        blockIsDisabled() {
            return this.blockInfoCountNum <= 0;
        }

    },
    methods: {
        getBlockActionId(blockAction) {
            return String(blockAction?.id ?? '');
        },
        onClickAction(actionEmitName = '', actionId = '') {
            if (!this.blockIsDisabled)
                this.$emit('onClickAction', actionEmitName, actionId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel-block {
        display: flex;
        padding: 7.5px 15px;
        background: #fff;
        border-radius: 10px;

        &_disabled {
            color: #AAB0B8
        }

        &__section {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        &__info {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;

            &-title {
                font-weight: 400;
            }

            &-count {
                font-weight: 600;
                margin-left: 5px;
            }
        }

        &__actions {
            display: flex;
        }
    }
</style>
