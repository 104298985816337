<template>
    <div :class="['base-input__wrapper', { isFocused }]">
        <label :for="id" class="base-input__label">{{ label }}</label>
        <input
            ref="input"
            :id="id"
            :type="type"
            :value="inputValue"
            class="base-input"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onEnter"
        />
        <slot />
    </div>
</template>

<script>
/** Компонент базового инпута */
export default {
    name: "BaseInput",
    /**
     * Входные данные компонента
     * @property {String} value - значение инпута
     * @property {String} placeholder - описание инпута
     */
    props: {
        value: {
            type: [String, Number],
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        id: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
    },
    /**
     * Данные компонента
     * @property {Object} inputLazyTimerId - идентификатор таймера ленивого ввода
     */
    data: () => ({
        inputLazyTimerId: null,
        focused: false,
    }),
    computed: {
        inputValue() {
            return this.isFocused ? this.value : ""
        },
        isFocused() {
            return this.focused || this.value
        }
    },
    methods: {
        /**
         * Событие ввода
         * @param {Object} event - объект события
         */
        onInput(event) {
            const { target = {} } = event;
            const { value = "" } = target;
            this.onInputLazy(String(value));
            this.$emit("onInput", String(value));
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = "") {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit("onInputLazy", value);
            }, 900);
        },
        onFocus() {
            this.focused = true;
            this.$emit("onFocus");
        },
        onBlur() {
            this.focused = false;
            setTimeout(() => {
                this.$emit("onBlur");
            }, 200);
        },
        onEnter() {
            this.$emit("onEnter");
        },
        setFocus() {
            this.$refs.input.focus();
            this.onFocus();
        }
    },
};
</script>

<style lang="scss" scoped>
.base-input {
    height: 100%;
    max-height: 36px;
    width: 100%;
    min-width: 20px;
    padding: 15px;
    margin-top: auto;
    border: none;
    background: transparent;

    &:focus {
        outline: none;
    }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50px;
        border-radius: 10px;
        background-color: #f2f2f2;
        transition: all .3s ease;
    }

    &__label {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translate(0%, -50%);

        font-size: 13px;
        line-height: 16px;
        font-weight: normal;
        margin-bottom: 5px;
        color: #999;
        transition: all .3s ease;
    }

    &__wrapper.isFocused &__label {
        top: 18px;
        font-size: 11px;
        line-height: 13px;
    }

}
</style>
