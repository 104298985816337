<template>
    <div class="control-panel">
        <div class="control-panel__container">
            <ControlPanelBlock
                v-for="block in blocks"
                :key="getBlockId(block)"
                :block="block"
                class="control-panel__block"
                @onClickAction="onClickAction"
            >
                <template #iconAction="{iconComponentName = ''}">
                    <slot 
                        name="iconAction" 
                        :iconComponentName="iconComponentName"
                    />
                </template>
            </ControlPanelBlock>
        </div>
    </div>
</template>

<script>
import ControlPanelBlock from './ControlPanelBlock.vue';
export default {
    name: 'ControlPanel',
    components: {ControlPanelBlock},
    props: {
        blocks: {
            type: Array,
            default: () => ([])
        },
    },
    methods: {
        getBlockId(block = {}) {
            return String(block?.id ?? '');
        },
        onClickAction(actionEmitName = '') {
            this.$emit(String(actionEmitName));
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel {
        &__container {
            display: flex;
        }

        &__block {
            margin-right: 10px;
        }
    }
</style>