<template>
    <BaseModal 
        :modalWidth="'1020px'"
        @close="onCloseModal"
    >
        <template v-slot:modalBody>
            <div class="reconciliation-modal-not-matched__body">
                <div class="reconciliation-modal-not-matched__section">
                    <div class="reconciliation-modal-not-matched__title">
                        Стороны в Oohdesk
                    </div>
                </div>

                <div class="reconciliation-modal-not-matched__section">
                    <ReconciliationTable
                        :columns="tableColumns"
                        :columnsShowed="tableColumns"
                        :rows="tableRows"
                        :isCanCheck="false"
                        :isCanCheckAll="false"
                        :isLoading="isLoading"
                        @onLazyload="onLazyloadTable"
                    />
                </div>

                <div class="reconciliation-modal-not-matched__actions">
                    <div class="reconciliation-modal-not-matched__action">
                        <BaseButton
                            view="border"
                            @click="onCloseModal"
                        >
                            Закрыть
                        </BaseButton>
                    </div>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '../../Base/BaseModal.vue';
import BaseButton from '../../Base/BaseButton.vue';
import ReconciliationTable from '../ReconciliationTable/ReconciliationTable.vue';
export default {
    name: 'ReconciliationModalNotMatchedAp',
    components: { 
        BaseModal,
        BaseButton,
        ReconciliationTable
    },
    props: {
        tableColumns: {
            type: Array,
            default: () => ([])
        },
        tableRows: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onCloseModal() {
            this.$emit('closeModal');
        },
        onLazyloadTable() {
            this.$emit('lazyloadTable');
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-modal-not-matched {
        &__section {
            margin-bottom: 20px;
        }

        &__title {
            font-size: 20px;
            font-weight: 500;
        }

        &__actions {
            display: flex;
            justify-content: end;
        }

        &__action {
            position: relative;
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }
</style>