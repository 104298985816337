<template>
    <div class="table-settings">
        <BaseModal
            :modalMaxHeight="'calc(100vh - 10px)'"
            :modalStyleList="{'overflow-y': 'auto', 'overflow-x': 'hidden'}"
            @close="closeModal"
        >
            <template v-slot:modalBody>
                <div class="table-settings__modal">
                    <div
                        class="table-settings__modal-section table-settings__modal-title"
                    >
                        Редактирование столбца
                    </div>

                    <div
                        class="table-settings__modal-section table-settings__modal-columns"
                    >
                    <BaseTextInput
                        isSearch
                        view="primary"
                        placeholder="Введите значение для поиска по полям"
                        class="table-settings__modal-search"
                        :value="searchedField"
                        @input="onInputSearchField"
                    />
                        <template v-for="item in editableFields">
                            <div
                                v-if="isOptionToEditSelect(item)"
                                :key="item.prop"
                                :class="[
                                    'table-settings__modal-field',
                                    { 'table-settings__modal-field_error': getIsFieldError(item) }
                                ]"
                            >
                                <BaseSelectV2
                                    :ref="getFieldRef(item)"
                                    :value="item.value"
                                    :options="item.options"
                                    :label="item.label"
                                    :customTitle="item.value"
                                    canSearch
                                    isFullWidth
                                    isSimpleCustomTitle
                                    @onSearchValue="onSearchOptions($event, item)"
                                    @lazyload="onLazyLoadOptions(item)"
                                    @check="onCheckOptionToEdit($event, item)"
                                    @onToggleDropdown="onToggleDropdown($event, item)"
                                />
                            </div>
                            <div
                                v-else
                                :key="item.label"
                                :class="[
                                    'table-settings__modal-field',
                                    { 'table-settings__modal-field_error': getIsFieldError(item) }
                                ]"
                            >
                                <BaseInputV2
                                    :ref="getFieldRef(item)"
                                    :id="item.prop"
                                    :label="item.label"
                                    :value="item.value"
                                    :type="item.type"
                                    class="table-settings__modal-input"
                                    @onInput="onInputValueToEdit($event, item)"
                                />
                            </div>
                        </template>
                    </div>

                    <div
                        class="table-settings__modal-section table-settings__modal-actions"
                    >
                        <BaseButton
                            view="border"
                            class="table-settings__modal-action"
                            @click="closeModal"
                        >
                            Отменить
                        </BaseButton>
                        <BaseButton
                            view="secondary"
                            class="table-settings__modal-action"
                            @click="saveChanges"
                        >
                            Сохранить
                        </BaseButton>
                    </div>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script>
import BaseModal from "../Base/BaseModal.vue";
import BaseButton from "../Base/BaseButton.vue";
import BaseSelectV2 from "../Base/BaseSelectV2.vue";
import BaseInputV2 from "../Base/BaseInputV2.vue";
import BaseTextInput from "../Base/BaseTextInput.vue";

export default {
    name: "TableSettings",
    components: {
        BaseModal,
        BaseButton,
        BaseSelectV2,
        BaseInputV2,
        BaseTextInput
    },
    props: {
        tableRowToEdit: {
            type: Object,
            default: () => ({}),
        },
        isFetchedOptions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchedField: ""
        }
    },
    computed: {
        editableFields() {
            return this.tableRowToEdit.editableFields.filter(item => {
                const condition = String(item.label).toLowerCase().startsWith(String(this.searchedField).toLowerCase()) || String(item.prop).toLowerCase().startsWith(String(this.searchedField).toLowerCase())|| String(item.value).toLowerCase().startsWith(String(this.searchedField).toLowerCase())

                if (condition) return item
            })
        },
        fieldError() {
            return String(this.tableRowToEdit?.fieldError ?? '');
        }
    },
    mounted() {
        if (this.fieldError)
            this.focusFieldError();
    },
    methods: {
        getFieldRef(field) {
            return `field-${ String(field?.prop ?? '') }`;
        },
        focusFieldError() {
            setTimeout(() => {
                this.$refs[`field-${ this.fieldError }`][0]?.setFocus();
                this.$refs[`field-${ this.fieldError }`][0]?.$el?.scrollIntoView({ behavior: "smooth" });
            });
        },
        getIsFieldError(field = {}) {
            return String(field?.prop ?? '') === this.fieldError;
        },
        onInputSearchField(value) {
            this.searchedField = value
        },
        isOptionToEditSelect(field) {
            return String(field?.type) === "select";
        },
        closeModal() {
            this.$emit("onCloseModal");
        },
        saveChanges() {
            this.closeModal();
            this.$emit("onSaveChanges");
        },
        onToggleDropdown(isOpen, select) {
            // if (isOpen && !select.options.length) {
            if (isOpen) {
                this.$emit("onFetchOptions", select);
            }
        },
        onLazyLoadOptions(select) {
            this.$emit("onLazyLoadOptions", select);
        },
        onSearchOptions(value, select) {
            this.$emit("onSearchOptions", { query: value, field: select.prop });
        },
        onInputValueToEdit(value, select) {
            this.$emit("onChangeRowValue", { value, select, parent: '' });
        },
        onCheckOptionToEdit(option, select) {
            this.$emit("onChangeRowValue", { value: option.id, select, parent: String(option?.parent ?? '') });
        },
    },
};
</script>

<style lang="scss" scoped>
.table-settings {
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.4s ease-out;

        &-icon {
            cursor: pointer;
        }

        &:hover {
            background-color: #f2f2f2;
        }
    }

    &__modal {
        width: 460px;
        &-title {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0;
            color: #000;
        }

        &-actions {
            display: flex;
            justify-content: end;
        }

        &-action {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        &-section {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        &-columns {
            overflow-y: auto;
            max-height: 100%;
        }

        &-search {
            margin-bottom: 15px;
        }

        &-field {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            &_error {
                border: 2px solid red;
                border-radius: 10px;
            }
        }

        &-column {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            cursor: grab;
            margin-right: 10px;
            border-radius: 4px;
            user-select: none;
            color: #000;

            &-checkbox {
                margin-right: 10px;
            }

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &:hover {
                background-color: #f2f2f2;
            }
        }
    }
}
</style>
