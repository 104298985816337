<template>
    <div
        :class="{'control-panel-block-action_disabled': isDisabled}"
        class="control-panel-block-action"
        @click="onClickAction"
    >
        <div class="control-panel-block-action__icon-section">
            <slot
                :iconComponentName="actionIconComponentName"
                name="iconAction" 
                class="control-panel-block-action__icon"
            />
        </div>
        <div class="control-panel-block-action__title">
            {{ actionTitle }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ControlPanelBlockAction',
    props: {
        action: {
            type: Object,
            default: () => ({})
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        actionTitle() {
            return String(this.action?.title ?? '');
        },
        actionEmitName() {
            return String(this.action?.emitName ?? '');
        },
        actionIconComponentName() {
            return String(this.action?.iconComponentName ?? '');
        }
    },
    methods: {
        onClickAction() {
            this.$emit('onClickAction', this.actionEmitName);
        }
    }
}
</script>

<style lang="scss" scoped>
    .control-panel-block-action {
        display: flex;
        align-items: center;
        padding: 7px 8px;
        cursor: pointer;
        border-radius: 4px;

        &:hover:not(.control-panel-block-action_disabled) {
            background-color: #f2f2f2;
        }

        &__icon {
            width: 18px;
            height: 18px;

            &-section {
                display: flex;
                align-items: center;
                margin-right: 8px;
            }
        }

        &__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
</style>