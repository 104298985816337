<template>
    <LayoutReconciliationTab
        :kitId="kitId"
        :pageType="pageType"
        :canSendToFact="canSendToFact"
        :canSendToBudget="canSendToBudget"
        :isCheckAddressProgramModal="isCheckAddressProgramModal"
        :isCanCheckTable="isApprovedPage"
        :tableRowsSelectedLength.sync="tableRowsSelectedLength"
        :tableRowsToEdit.sync="tableRowsToEdit"
        @onFilterData="onFilterData"
        @onChangeState="onChangeState"
        @onChangeDisableBtn="onChangeDisableBtn"
        @onSendedToFact="onChangeSendedToFactState(false)"
        @onSendedToBudget="onChangeSendedToBudgetState(false)"
        @onCloseModalCheckAddressProgram="onCloseModalCheckAddressProgram"
        @onTransactionsRemove="onTransactionsRemove"
        @onOpenSingleEditModal="onOpenSingleEditModal"
        @toggleRevise="onToggleRevise"
    >
        <template #default="slotProps">
            <div class="page-reconciliation-approved__actions">
                <ReconciliationSummary
                    :summaryData="summaryData"
                    :isLoading="isLoadingSummary"
                    class="page-reconciliation-approved__summary"
                    @onSummaryItemClick="onSummaryItemClick($event, slotProps)"
                />
                <BaseButton
                    v-if="isFactPage"
                    view="secondary"
                    @click="onChangeSendedToBudgetState(true)"
                >
                    Отправить в бюджет
                </BaseButton>
            </div>

            <div class="page-reconciliation-approved__actions">
                <ControlPanel
                    v-if="isApprovedPage"
                    :blocks="controlPanelBlocks"
                    class="page-reconciliation-approved__control-panel"
                    @select="onSelectAllTableRows(slotProps)"
                    @reset="onResetAllTableRows(slotProps)"
                    @remove="onRemoveTableRowsSelected(slotProps)"
                    @edit="onEditTableRowsSelected"
                    @send="onChangeSendedToFactState(true)"
                    @download="onDownloadTableRows(slotProps)"
                    @turnOnRevises="onTurnOnRevises(slotProps)"
                    @turnOffRevises="onTurnOffRevises(slotProps)"
                >
                    <template #iconAction="{ iconComponentName = '' }">
                        <component
                            :is="iconComponentName"
                            class="page-reconciliation-approved__control-panel-action-icon"
                        />
                    </template>
                </ControlPanel>
            </div>

            <ReconciliationModalMassEdit
                v-if="isMassEditModal"
                :tableRowsToEdit="tableRowsToEdit"
                :selectedCount="tableRowsSelectedLength"
                @onFetchOptions="onFetchRowsOptions($event, slotProps)"
                @onCloseModal="onCloseModalMassEdit"
                @onSaveChanges="onSaveChanges(slotProps)"
                @onLazyLoadOptions="onLazyLoadOptions"
                @onSearchOptions="onSearchOptions"
                @onChangeRowValue="onChangeRowValue($event, slotProps)"
            />

            <ReconciliationTableRowEditModal
                v-if="isSingleEditModal"
                :tableRowToEdit="tableRowsToEdit"
                @onLazyLoadOptions="onLazyLoadOptions"
                @onSearchOptions="onSearchOptions"
                @onChangeRowValue="onChangeRowValue($event, slotProps)"
                @onFetchOptions="onFetchRowOptions($event, slotProps)"
                @onCloseModal="onCloseSingleEditModal(slotProps)"
                @onSaveChanges="onSaveChanges(slotProps)"
            />

            <ReconciliationModalNotMatchedAp
                v-if="isModalNotMatchedAp"
                :tableColumns="tableDataNotMatchedAp.tableColumns"
                :tableRows="tableDataNotMatchedAp.tableRows"
                :isLoading="tableDataNotMatchedAp.isLoading"
                @lazyloadTable="onLazyloadTableNotMathedAp(slotProps)"
                @closeModal="onCloseModalNotMatchedAp"
            />

            <ReconciliationImportRealtimeSystem @endDownload="onEndDownload"/>
        </template>
    </LayoutReconciliationTab>
</template>

<script>
import BaseButton from "@/components/Base/BaseButton";
import ControlPanel from "../components/ControlPanel/ControlPanel.vue";
import LayoutReconciliationTab from "../layouts/LayoutReconciliationTab.vue";
import ReconciliationSummary from "../components/Reconciliation/ReconciliationSummary/ReconciliationSummary.vue";
import ReconciliationModalMassEdit from "../components/Reconciliation/ReconciliationModals/ReconciliationModalMassEdit.vue";
import ReconciliationTableRowEditModal from "../components/Reconciliation/TableRowEditModal";
import ReconciliationModalNotMatchedAp from "../components/Reconciliation/ReconciliationModals/ReconciliationModalNotMatchedAp.vue";
import IconSelectAll from "../components/Icons/IconSelectAll.vue";
import IconDownload from "../components/Icons/IconDownload.vue";
import IconReset from "../components/Icons/IconReset.vue";
import IconDelete from "../components/Icons/IconDelete.vue";
import IconEdit from "../components/Icons/IconEdit.vue";
import IconSend from "../components/Icons/IconSend.vue";
import IconPlus from '../components/Icons/IconPlus.vue';
import IconMinus from '../components/Icons/IconMinus.vue';
import ServiceReconciliation from "../services/ServiceReconciliationV2/ServiceReconciliation";
import ServiceReconciliationTransaction from "../services/ServiceReconciliationV2/ServiceReconciliationTransaction";
import ReconciliationImportRealtimeSystem from '../components/Reconciliation/ReconciliationImport/ReconciliationImportRealtimeSystem.vue';

export default {
    name: "PageReconciliationApproved",
    components: {
        BaseButton,
        ControlPanel,
        LayoutReconciliationTab,
        ReconciliationSummary,
        ReconciliationModalMassEdit,
        ReconciliationTableRowEditModal,
        ReconciliationModalNotMatchedAp,
        IconSelectAll,
        IconDownload,
        IconReset,
        IconDelete,
        IconEdit,
        IconSend,
        IconPlus,
        IconMinus,
        ReconciliationImportRealtimeSystem
    },
    props: {
        kitId: {
            type: String,
            default: "",
        },
        pageType: {
            type: String,
            default: "approved",
            validator: (value) =>
                ["kits", "offers", "approved", "more", "fact"].includes(value),
        },
    },
    data: () => ({
        summaryData: {},
        isLoadingSummary: false,
        isDisabledToFactBtn: true,
        canSendToFact: false,
        canSendToBudget: false,
        isCheckAddressProgramModal: false,
        tableRowsSelectedLength: 0,
        tableRowsToEdit: {},
        tableSelectedRowsIds: [],
        isMassEditModal: false,
        isSingleEditModal: false,
        isModalNotMatchedAp: false,
        tableDataNotMatchedAp: {
            page: 0,
            pageCount: 1,
            tableColumns: [],
            tableRows: [],
            isLoading: false
        }
    }),
    computed: {
        isApprovedPage() {
            return this.pageType === "approved";
        },
        isFactPage() {
            return this.pageType === "fact";
        },
        summaryItems() {
            return this.summaryData?.items || []
        },
        summaryItemCount() {
            return this.summaryData?.itemCount || 0
        },
        controlPanelBlocks() {
            return [
                {
                    id: "founded",
                    infoTitle: "Найдено:",
                    infoCount: this.summaryItemCount,
                    actions: [
                        {
                            id: "select",
                            title: "Выбрать все",
                            emitName: "select",
                            iconComponentName: "IconSelectAll",
                        },
                        {
                            id: "download",
                            title: "Скачать Excel",
                            emitName: "download",
                            iconComponentName: "IconDownload",
                        },
                    ],
                },
                {
                    id: "selected",
                    infoTitle: "Выбрано:",
                    infoCount: this.tableRowsSelectedLength,
                    actions: [
                        {
                            id: "reset",
                            title: "Сбросить",
                            emitName: "reset",
                            iconComponentName: "IconReset",
                        },
                        {
                            id: "edit",
                            title: "Редактировать",
                            emitName: "edit",
                            iconComponentName: "IconEdit",
                        },
                        {
                            id: "send",
                            title: "Отправить в факт",
                            emitName: "send",
                            iconComponentName: "IconSend",
                        },
                        {
                            id: "remove",
                            title: "Удалить",
                            emitName: "remove",
                            iconComponentName: "IconDelete",
                        },
                        {
                            id: "turnOnRevises",
                            title: "Сверить с оператором",
                            emitName: "turnOnRevises",
                            iconComponentName: "IconPlus",
                        },
                        {
                            id: "turnOffRevises",
                            title: "Отменить сверку",
                            emitName: "turnOffRevises",
                            iconComponentName: "IconMinus",
                        },
                    ],
                },
            ];
        },
    },
    mounted() {
        this.isLoadingSummary = true;
    },
    methods: {
        onSummaryItemClick(emit, slotProps) {
            // if (emit === 'onMatch') this.onOpenModalCheckAddressProgram()
            if (emit === 'onMatch') {
                slotProps?.checkAddressProgram();

                this.$notify.dNotify({
                    type: 'success',
                    title: 'Задача на автоматическую сверку успешно добавлена в очередь',
                    description: 'Дождитесь уведомления',
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
            else if (emit === 'onErrorsFilter') {
                slotProps.onSetAllErrorsFilter(emit);
            } else if (emit === 'showSides')
                this.onOpenModalNotMatchedAp(slotProps);
            else if (emit === 'getNotSupplierRevised')
                slotProps.setNotSupplierRevisedFilter(emit);
        },
        onChangeDisableBtn(value) {
            this.isDisabledToFactBtn = value;
        },
        onChangeSendedToFactState(value) {
            this.canSendToFact = value;
        },
        onChangeSendedToBudgetState(value) {
            this.canSendToBudget = value;
        },
        onFilterData(filterData = []) {
            this.getSummary(filterData);
        },
        onChangeState(filterData = []) {
            this.getSummary(filterData);
        },
        async getSummary(filterData = []) {
            this.isLoadingSummary = true;
            await ServiceReconciliation.getSummary(
                this.pageType,
                filterData,
                this.kitId,
                ({itemCount = 0, items = []}) => {
                    this.summaryData = {itemCount, items};
                    this.isLoadingSummary = false;
                },
                () => (this.isLoadingSummary = false)
            );
        },
        onOpenModalCheckAddressProgram() {
            this.isCheckAddressProgramModal = true;
        },
        onCloseModalCheckAddressProgram() {
            this.isCheckAddressProgramModal = false;
        },
        onSelectAllTableRows(slotProps = {}) {
            slotProps.onSelectAllTableRows()
        },
        onResetAllTableRows(slotProps = {}) {
            slotProps.onResetAllTableRows()
        },
        onDownloadTableRows(slotProps = {}) {
            // const actionType = this.routerNameCurrent === 'reconciliationApproved' ? 'approved' : 'done';
            const actionType = this.$route.name === 'reconciliationApproved' ? 'approved' : 'done';
            const filterData = slotProps?.filterSelectedData ?? [];

            this.getDownloadLink(actionType, filterData);
        },
        async getDownloadLink(actionType, filterData = []) {
            await ServiceReconciliation.getDownloadLink(actionType, filterData, (link) => window.open(link, '_blank'), () => alert('Не удалось скачать данные'));
        },
        onRemoveTableRowsSelected(slotProps = {}) {
            if (confirm("Вы уверены что хотите удалить выбранные конструкции?"))
                slotProps?.removeRows();
        },
        onChangeRowValue({ value, select, parent: parentValue = '' }, slotProps) {
            const parentProp = parentValue ? String(select?.prop).replace('_entity', '') : '';
            const selectPropArr = String(select?.prop ?? '').split('|');
            const childProp = selectPropArr[1] ? `${selectPropArr[0] ?? ''}_entity|${selectPropArr[1]}` : `${selectPropArr[0] ?? ''}_entity`;
            let isChildProp = false;

            const editableFields = this.tableRowsToEdit.editableFields.map(
                (item) => {
                    if (item.prop === select.prop) item.value = value;
                    if (parentValue && String(item?.prop ?? '') === parentProp)
                        item.value = parentValue;
                    if (String(item?.prop ?? '') === childProp) {
                        item.value = '';
                        isChildProp = true;
                    }

                    return item;
                }
            );

            this.tableRowsToEdit.editableFields = editableFields;
            slotProps?.onChangeRowValue(select.prop, value, parentProp, parentValue, isChildProp, childProp);
        },
        async onLazyLoadOptions(select) {
            await this.getSelectOptions(select, (result) => {
                const editableFields = this.tableRowsToEdit.editableFields.map(
                    (item) => {
                        if (item.prop === select.prop) {
                            item.options = [...item.options, ...result.items];
                            item.page = item.page + 1;
                            item.totalCount = result.total_count;
                        }
                        return item;
                    }
                );
                this.tableRowsToEdit.editableFields = editableFields;
            });
        },
        async getSelectOptions(select = {}, actionAfter = () => {}) {
            let { page = 1, isCanGet = false } = this.getSelectOptionsBefore(
                select.prop
            );

            const reqBody = {
                field: String(select.prop ?? ""),
                page: ++page,
            };

            if (isCanGet) {
                await ServiceReconciliation.getTransactionsSelectOptions(
                    reqBody,
                    (res) => actionAfter(res)
                );
            }
        },
        getSelectOptionsBefore(selectProp = "") {
            let currentSelect = this.tableRowsToEdit.editableFields.find(
                (item) => item.prop === selectProp
            );
            let isCanGet =
                this.tableRowsToEdit.editableFields.length <=
                currentSelect.totalCount;

            return {
                page: parseInt(currentSelect?.page),
                isCanGet,
            };
        },
        async onSearchOptions({ query, field }) {
            await ServiceReconciliation.getTransactionsSelectOptions(
                { query, field },
                (result) => {
                    const editableFields =
                        this.tableRowsToEdit.editableFields.map((item) => {
                            if (item.prop === field) {
                                item.options = result.items;
                                item.totalCount = result.total_count;
                            }
                            return item;
                        });
                    this.tableRowsToEdit.editableFields = editableFields;
                }
            );
        },
        onSaveChanges(slotProps) {
            slotProps?.onSaveChanges();
        },
        onFetchRowsOptions(select, slotProps) {
            slotProps?.onFetchRowsOptions(select);
        },
        onFetchRowOptions(select, slotProps) {
            slotProps?.onFetchRowOptions(select);
        },
        onTransactionsRemove(filterData = []) {
            this.getSummary(filterData);
        },
        onEditTableRowsSelected() {
            this.isMassEditModal = true;
        },
        onCloseModalMassEdit() {
            this.isMassEditModal = false;
        },
        onOpenSingleEditModal() {
            this.isSingleEditModal = true;
        },
        onCloseSingleEditModal(slotProps = {}) {
            this.isSingleEditModal = false;

            slotProps?.closeSingleEditModal();
        },
        onEndDownload(isSuccess = false) {
            if (isSuccess)
                document.location.reload();
            else {
                this.$notify.dNotify({
                    type: 'error',
                    title: 'Ошибка загрузки файла',
                    description: 'При загрузке файла произошла непредвиденная ошибка',
                    duration: Infinity,
                    position: 'bottom-right'
                });
            }
        },
        onTurnOnRevises(slotProps = {}) {
            this.toggleRevises(slotProps, true);
        },
        onTurnOffRevises(slotProps = {}) {
            this.toggleRevises(slotProps, false);
        },
        async toggleRevises(slotProps = {}, isRevised = false) {
            const priceIds = this.tableRowsToEdit?.rowsGuids ?? [];
            const rowsIds = this.tableRowsToEdit?.rowsIds ?? [];

            await ServiceReconciliation.toggleRevises(priceIds, isRevised);

            slotProps?.toggleRevisesAfter(rowsIds, isRevised);
        },
        onToggleRevise(isRevised = false) {
            this.summaryData = {
                itemCount: this.summaryItemCount,
                items: this.summaryItems.map(summaryItem => {
                    if (String(summaryItem?.id ?? '') === 'countSupplierRevised')
                        summaryItem.total = isRevised ? parseInt(summaryItem.total) + 1 : parseInt(summaryItem.total) - 1;

                    return summaryItem;
                })
            };
        },
        onOpenModalNotMatchedAp(slotProps = {}) {
            this.isModalNotMatchedAp = true;
            this.getTableNotMatchedAp(slotProps?.filterSelectedData ?? []);
        },
        onCloseModalNotMatchedAp() {
            this.isModalNotMatchedAp = false;

            this.tableDataNotMatchedAp.page = 0;
            this.tableDataNotMatchedAp.pageCount = 1;
            this.tableDataNotMatchedAp.tableColumns = [];
            this.tableDataNotMatchedAp.tableRows = [];
            this.tableDataNotMatchedAp.isLoading = false;
        },
        async getTableNotMatchedAp(filterSelectedData = []) {
            this.tableDataNotMatchedAp.isLoading = true;

            const tableData = await ServiceReconciliationTransaction.getTableNotMatchedAp(this.tableDataNotMatchedAp.page, filterSelectedData);

            this.tableDataNotMatchedAp.pageCount = parseInt(tableData?.pageCount ?? 1);
            this.tableDataNotMatchedAp.tableColumns = tableData?.tableColumn ?? [];
            this.tableDataNotMatchedAp.tableRows.push(...tableData?.tableRows);

            this.tableDataNotMatchedAp.isLoading = false;
        },
        onLazyloadTableNotMathedAp(slotProps = {}) {
            this.tableDataNotMatchedAp.page += 1;

            if (this.tableDataNotMatchedAp.page <= this.tableDataNotMatchedAp.pageCount)
                this.getTableNotMatchedAp(slotProps?.filterSelectedData ?? []);
        }
    },
};
</script>

<style lang="scss" scoped>
.page-reconciliation-approved {
    &__control-panel {
        display: flex;
        margin-top: 10px;

        &-action-icon {
            width: 18px;
            height: 18px;
        }
    }

    &__summary {
        margin-top: 10px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.page-reconciliation-tab {
    &__control {
        &-container {
            display: flex;
        }

        &-btn {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
}
</style>
